const NotificationSuccess = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
  >
    <path
      d="M14.219 25.188C20.277 25.188 25.188 20.277 25.188 14.219S20.277 3.25 14.219 3.25 3.25 8.161 3.25 14.219 8.161 25.188 14.219 25.188"
      fill="#03A644"
    />
    <path
      d="m19.094 10.969 -6.5 6.5 -3.25 -3.25"
      stroke="white"
      strokeWidth="1.625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default NotificationSuccess
