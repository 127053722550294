import { FlatFileNodeDto } from "legado-generated-api-client"
import { Dispatch } from "react"
import { NavigateFunction } from "react-router-dom"
import { getFolder } from "../../api/api-client/api-handler"
import {
  ConnectionStatus,
  IFile,
  IFolder,
  IProviderStatus,
  IUser,
} from "../../api/api-client/api-types"
import { findFolderByFileId } from "../../api/api-client/file/findFolderByFileId"
import { ApiController } from "../../api/apiController"
import {
  deleteFileInClientsFiles,
  deleteFileInFlatFileList,
  deleteFileInFolder,
  deleteFileInSharedWithMeFolder,
  deleteFolder,
  deleteFolderInSharedWithMe,
  setLoadingState,
  setPinnedFoldersFiles,
  setProviderStatuses,
  setSelectedFile,
  setSelectedFolder,
} from "../../contexts/application/action"
import { setDisplaySuccessOrErrorMessage } from "../../contexts/toasts"
import { IToastAction } from "../../contexts/toasts/reducer"
import { GetGAFolderType, sendGaData } from "../../utils/gaEvents"
import {
  deleteItemLocalStorage,
  deleteThumbnailsInFolderTree,
} from "../../utils/localStorage"

const SuccessFeedback = ({
  nodeName,
  isFolder,
  isSection,
}: {
  nodeName: string
  isFolder: boolean
  isSection: boolean
}) => (
  <span>
    The
    <span className="fw-500"> {nodeName} </span>
    {isSection
      ? "section and all contained files have been successfully deleted."
      : isFolder
      ? "folder and all contained files has been successfully deleted."
      : "file has been successfully deleted."}
  </span>
)

const ErrorFeedback = ({ nodeName }: { nodeName: string }) => (
  <span>
    An error has occurred while trying to delete
    <span className="fw-500"> {nodeName}</span>
  </span>
)

export const deleteFile = async (
  file: IFile,
  isShared: boolean,
  onClose: () => void,
  dispatch: Dispatch<any>,
  toastDispatch: Dispatch<IToastAction>,
  folders: IFolder[],
  sharedWithMeFolders: IFolder[] | undefined,
  pinnedFoldersAndFiles: IFolder | undefined,
  currentUser: IUser | undefined,
  flatFileList?: FlatFileNodeDto[] | undefined,
  documentLibraryClientFiles?: IFile[] | undefined
) => {
  dispatch(setLoadingState(true))
  const parentFolder = findFolderByFileId(
    file.id,
    isShared ? sharedWithMeFolders! : folders
  )
  sendGaData({
    name: "File_Delete",
    is_node_owner: file.isOwner ? "Y" : "N",
    file_extension: file.extension ?? "",
    ...(parentFolder && {
      folder_type: GetGAFolderType(parentFolder),
      folder_depth: parentFolder.level,
      folder_name: parentFolder.name,
    }),
    currentUser,
  })
  const api = ApiController.getInstance()
  const fileRes = await api.deleteFile(file.id)
  deleteItemLocalStorage(file.id)

  onClose()
  dispatch(setLoadingState(false))

  if (fileRes) {
    if (pinnedFoldersAndFiles?.contents?.find((f) => f.id === file.id)) {
      const newPinnedFoldersAndFiles = pinnedFoldersAndFiles
      newPinnedFoldersAndFiles.contents = pinnedFoldersAndFiles.contents.filter(
        (f) => f.id !== file.id
      )
      dispatch(
        setPinnedFoldersFiles({
          pinnedFoldersAndFiles: newPinnedFoldersAndFiles,
        })
      )
    }
    dispatch(setSelectedFile(undefined))
    if (!isShared) {
      file.parentId && dispatch(setSelectedFile(undefined))
      dispatch(deleteFileInFolder({ file: file, folderId: file.parentId! }))
    } else {
      const nodeFolder = getFolder({
        folders: sharedWithMeFolders!,
        folderId: file.parentId!,
      })
      if (flatFileList) {
        dispatch(deleteFileInFlatFileList(file.id))
      }
      if (documentLibraryClientFiles) {
        dispatch(deleteFileInClientsFiles(file.id))
      }
      if (nodeFolder) {
        dispatch(
          deleteFileInSharedWithMeFolder({
            file: file,
            folder: nodeFolder,
          })
        )
      }
    }
    toastDispatch(
      setDisplaySuccessOrErrorMessage({
        message: (
          <SuccessFeedback
            nodeName={file.name}
            isFolder={false}
            isSection={false}
          />
        ),
        messageType: "SUCCESS",
      })
    )
    sendGaData({
      name: "File_Delete_Response",
      is_node_owner: file.isOwner ? "Y" : "N",
      file_extension: file.extension ?? "",
      ...(parentFolder && {
        folder_type: GetGAFolderType(parentFolder),
        folder_name: parentFolder.name,
      }),
      request_status: "success",
      currentUser,
    })
  } else {
    toastDispatch(
      setDisplaySuccessOrErrorMessage({
        message: <ErrorFeedback nodeName={file.name} />,
        messageType: "ERROR",
      })
    )
    sendGaData({
      name: "File_Delete_Response",
      is_node_owner: file.isOwner ? "Y" : "N",
      file_extension: file.extension ?? "",
      ...(parentFolder && {
        folder_type: GetGAFolderType(parentFolder),
        folder_depth: parentFolder.level,
        folder_name: parentFolder.name,
      }),
      request_status: "success",
      currentUser,
    })
  }
}

export const deleteIFolder = async (
  folder: IFolder,
  isShared: boolean,
  onClose: () => void,
  dispatch: Dispatch<any>,
  toastDispatch: Dispatch<IToastAction>,
  pinnedFoldersAndFiles: IFolder | undefined,
  currentUser: IUser | undefined,
  providerStatuses: IProviderStatus[],
  navigate: NavigateFunction,
  isSection: boolean
) => {
  dispatch(setLoadingState(true))
  const isConnected = folder.folderType === "ConnectedAccountFolder"
  sendGaData({
    name: "Folder_Delete",
    folder_type:
      folder.isDeletable && !folder.isEditable ? "Suggested" : "Custom", //Suggested folders are deleteable but not editable
    folder_name: folder.name,
    folder_depth: folder.level,
    is_node_owner: folder.isOwner ? "Y" : "N",
    currentUser,
  })
  const api = ApiController.getInstance()
  var res = isConnected
    ? await api.deleteConnectedFolder(folder.id)
    : await api.deleteFolder(folder.id)

  if (providerStatuses && res && isConnected) {
    const newStatuses = providerStatuses.map((p) => {
      if (p.folderId === folder.id) {
        return { ...p, connectionStatus: ConnectionStatus.REMOVED }
      }
      return p
    })
    dispatch(setProviderStatuses(newStatuses))
  }
  onClose()
  dispatch(setLoadingState(false))

  if (res) {
    if (pinnedFoldersAndFiles?.childFolders?.find((f) => f.id === folder.id)) {
      const newPinnedFoldersAndFiles = pinnedFoldersAndFiles
      newPinnedFoldersAndFiles.childFolders =
        pinnedFoldersAndFiles.childFolders.filter((f) => f.id !== folder.id)
      dispatch(
        setPinnedFoldersFiles({
          pinnedFoldersAndFiles: newPinnedFoldersAndFiles,
        })
      )
    }
    if (!isShared) {
      dispatch(deleteFolder({ folderId: folder.id }))
      if (folder.parentId) dispatch(setSelectedFolder(folder.parentId))
      else navigate("/")
    } else {
      dispatch(deleteFolderInSharedWithMe({ folderId: folder.id }))
    }
    deleteThumbnailsInFolderTree(folder)

    toastDispatch(
      setDisplaySuccessOrErrorMessage({
        message: (
          <SuccessFeedback
            nodeName={folder.name}
            isFolder
            isSection={isSection}
          />
        ),
        messageType: "SUCCESS",
      })
    )
    sendGaData({
      name: "Folder_Delete_Response",
      folder_type: GetGAFolderType(folder),
      folder_name: folder.name,
      folder_depth: folder.level,
      is_node_owner: folder.isOwner ? "Y" : "N",
      request_status: "success",
      currentUser,
    })
  } else {
    toastDispatch(
      setDisplaySuccessOrErrorMessage({
        message: <ErrorFeedback nodeName={folder.name} />,
        messageType: "ERROR",
      })
    )
    sendGaData({
      name: "Folder_Delete_Response",
      folder_type: GetGAFolderType(folder),
      folder_name: folder.name,
      folder_depth: folder.level,
      is_node_owner: folder.isOwner ? "Y" : "N",
      request_status: "error",
      currentUser,
    })
  }
}
